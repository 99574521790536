import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import RelativeMoment from './RelativeMoment';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: 'normal',
    padding: 0,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  container: ({ image, narrow }) => ({
    position: 'relative',
    width: '100%',
    paddingTop: narrow ? '33%' : '56.25%',
    backgroundImage: `url('${image}')`,
    backgroundSize: 'cover, cover',
    backgroundPosition: 'center center',
  }),
  dim: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
    transition: 'opacity 0.1s',
    opacity: 1,
    '&:hover': {
      opacity: 0.5,
    },
  },
  title: {
    fontSize: `${20 / 16}rem`,
    fontWeight: theme.typography.fontWeightLight,
  },
  content: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    color: theme.palette.text.white,
  },
  timestamp: {
    color: theme.palette.grey[300],
  },
}));

const CoverItem = ({ title, subtext, timestamp, image, to, narrow }) => {
  const classes = useStyles({ image, narrow });

  return (
    <MenuItem component={Link} to={to} className={classes.menuItem}>
      <Box className={classes.container}>
        <Box className={classes.dim} />
        <Box p={3} className={classes.content}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2">{subtext}</Typography>
          {timestamp && (
            <Typography variant="body2" className={classes.timestamp}>
              <RelativeMoment>{timestamp}</RelativeMoment>
            </Typography>
          )}
        </Box>
      </Box>
    </MenuItem>
  );
};

CoverItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  timestamp: PropTypes.string,
  image: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  narrow: PropTypes.bool,
};

CoverItem.defaultProps = {
  timestamp: null,
  narrow: false,
};

export default CoverItem;
