import React from 'react';
import { Link } from 'gatsby';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const LiveStreamSnippet = () => (
  <>
    <Box mb={4}>
      <Typography variant="h4">Live Stream</Typography>
    </Box>

    <Box mb={4}>
      <Typography variant="body1">
        Join us for a live video broadcast of our Sunday meeting each week at
        10:30am MST.
      </Typography>
    </Box>

    <div>
      <Button
        component={Link}
        to="/live"
        color="secondary"
        startIcon={<PlayArrowIcon />}
      >
        Weekly Live Stream
      </Button>
    </div>

    <div>
      <Button
        href="https://www.youtube.com/playlist?list=PLDzEX8mCMdiLmC14gFp9NZAHDwYSpTdH4"
        color="secondary"
        startIcon={<PlayArrowIcon />}
      >
        Words of Encouragement
      </Button>
    </div>

    <div>
      <Button
        href="https://www.youtube.com/playlist?list=PLDzEX8mCMdiJHi200xAK0v41zHojAxoAP"
        color="secondary"
        startIcon={<PlayArrowIcon />}
      >
        RLCF Praise Live
      </Button>
    </div>
  </>
);

export default LiveStreamSnippet;
