import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactMoment from 'react-moment';
import { Typography, Box, Divider, Grid } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CoverItem from '../../components/CoverItem';
import LiveStreamSnippet from '../../components/LiveStreamSnippet';
import { getConferenceImage } from '../../utils';

const Page = ({ pageContext }) => {
  const { conferences, pageNum, totalPages } = pageContext;

  return (
    <Layout>
      <SEO title="Conferences" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Conferences</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {conferences.map((conference) => (
              <Grid item key={conference.id} xs={12} md={6}>
                <CoverItem
                  key={conference.slug}
                  title={conference.title}
                  subtext={
                    <>
                      <ReactMoment format="MMMM D">
                        {conference.startDate}
                      </ReactMoment>{' '}
                      &mdash;{' '}
                      <ReactMoment format="MMMM D, YYYY">
                        {conference.endDate}
                      </ReactMoment>
                    </>
                  }
                  image={getConferenceImage(conference, 'imageThumb')}
                  to={`/events/conferences/${conference.slug}`}
                />
              </Grid>
            ))}
          </Grid>

          <Box mt={6} mb={4}>
            <Pagination
              size="large"
              page={pageNum}
              count={totalPages}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  component={Link}
                  to={
                    item.page === 1
                      ? '/events/conferences'
                      : `/events/conferences/${item.page}`
                  }
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item md={4} xs={12}>
          <Box mb={8}>
            <LiveStreamSnippet />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
